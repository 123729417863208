import React, { useEffect, useState, useRef } from "react";
// import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import  Header  from "../../components/Header";
// import { useNavigate } from "react-router-dom";
import { Table, Input, Loader, Checkbox} from 'rsuite';
// import { selectAuth } from './../../shared-redux/Auth/selectors';
import { toast } from 'react-toastify';
import { delayedRedirect, redirect } from '../../../utils/response';
import userService from '../../../services/userService';
import UserInfo from './../../components/UserInfo';
import Billing from './../../components/Billing';
import Preferences from './../../components/Preferences';
import { useParams } from "react-router-dom";

export const Account = () => {

    let { tab } = useParams();
    const tableRef = useRef()
    const dispatch = useDispatch()
    // const navigate = useNavigate();
    const [active, setActive] =  useState((tab === "1") ? 1 : 0)
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);
    const [dashboardVisible, setDashboardVisible] = useState("false")
    const [subscriptionDetails, setSubscriptionDetails] = useState()
    const [usedLimit, setUsedLimit] = useState(0);

    const checkSubscription = (value) => {
        try{
            dispatch({
                type: authActions.checkSubscription.type,
                payload: {
                userId:value.id,
                // showToastMsgFor:"signup",
                successCallback: (data) => {
                    setSubscriptionDetails(data)
                }}
            })
        }catch(e){

        }
       
    };

    const getProfile = (userId) => {

        //console.log("values= ==", values)
        dispatch({
            type: authActions.getUserProfile.type,
            payload: {
                userId,
                successCallback:(user) => {
                    // redirect('/data-list')
                    // console.log("profile ==", user)
                    setUser(user)
                   
                }
            }
        })
    }

    const getUserData = (userId) => {
        dispatch({
          type: authActions.getUser.type,
          payload:{
            userId:userId,
            successCallback: (data) => {
            //   setUser(data);
            // console.log("limit ==", data)
            setUsedLimit(data.usedLimit)
            }
          }
        })
      }
  
    const onTabClick = (index) => {
        setActive(index)
    }

    useEffect(() => {
       
        userService.getUser().then(value => {
            // console.log("value user= ==", value)
            if (value){
                setUser(value);
                checkSubscription(value)
            }
             
          });
          userService.getCurrentUserId().then(value => {
            // console.log("userId= ==", value)
            setUserId(value);
            getProfile(value)
            getUserData(value)
          });
          userService.getDashboardVisible().then(value => {
            setDashboardVisible(value)
        });
    },[active])

    const onSave = (values) => {

        //console.log("values= ==", values)
        dispatch({
            type: authActions.updateUser.type,
            payload: {
                data: {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    phone: (values.phone) ? values.phone : ''
                },
                userId,
                successCallback:() => {
                    // redirect('/data-list')
                }
            }
        })
    }

    const updateHeaderCallback = (seDashboardView) => {
        setDashboardVisible(seDashboardView)
    }

    return(
        <div className="font-Poppins pb-10 overflow-scroll bg-gradient-to-r from-white from-40% via-green-300 via-60% to-white w-full h-screen">
            {
                (dashboardVisible === "false" && (!subscriptionDetails || (subscriptionDetails && subscriptionDetails.paymentStatus !== "Processing"))) && (
                    <b className="cursor-pointer" onClick={() => {
                        window.open(`${process.env.REACT_APP_CHECKOUT_LINK}?prefilled_email=${user.email}&client_reference_id=${user.id}`, '_self')
                    }}>
                    <div id="message" className="bg-orange-100 p-3 justify-center flex">{`Your free trial has ended. Please upgrade to Pro plan to access dashboard.`}</div></b>
                )
            }
            {
                (dashboardVisible === "false" && subscriptionDetails && subscriptionDetails.paymentStatus === "Processing") && (
                    <div id="message" className="bg-orange-100 p-3 justify-center flex">{`Please wait while your payment is under process.`}</div>
                )
            }
            <Header dashboardVisible={dashboardVisible} active={0} />
            {/* <div className="w-full p-8 flex pl-16">
                <img src="/images/logo.png" className="h-16" />
            </div> */}
           
            <div className="w-full px-5 md:px-16 lg:px-16 pb-16">
                <div className="w-full px-10 pb-16 py-5 bg-white border rounded-md">
                
                    <div className="flex flex-wrap mt-0 gap-y-2 gap-x-3">
                        <div onClick={() => onTabClick(0)} 
                            className={`flex gap-x-1 font-bold cursor-pointer px-8 py-2 rounded-md text-sm  ${active ===0 ? 'text-green-200 border-2 border-solid border-green-200 bg-green-100': 'bg-white text-orange-200 border-2 border-solid border-orange-200'}`}>
                           User Info
                        </div>
                        {
                            (subscriptionDetails && subscriptionDetails.subscriptionDetails) && (
                                <div onClick={() => onTabClick(1)} 
                                    className={`flex gap-x-1 font-bold cursor-pointer px-8 py-2 rounded-md text-sm  ${active ===1 ? 'text-green-200 border-2 border-solid border-green-200 bg-green-100 ': 'bg-white text-orange-200 border-2 border-solid border-orange-200'}`}>
                                    Billing
                                </div>
                            )
                        }
                       
                        {/* <input type="file" id="myFile" name="filename" className="hidden" accept=".csv" onChange={(e) => {onImport(e.target.files);e.target.value = ''}}></input> */}
                        {/* <div onClick={() => onTabClick(2)} 
                            className={`flex gap-x-1 font-bold cursor-pointer px-8 py-2 rounded-md text-sm  ${active ===2 ? 'text-green-200 border-2 border-solid border-green-200 bg-green-100 ': 'bg-white text-orange-200 border-2 border-solid border-orange-200'}`}>
                            Preferences
                        </div> */}
                    </div>
                    {
                        <div>
                            {
                                active === 0 && user.id && (
                                    <UserInfo onSave={onSave} user={user} usedLimit={usedLimit} />
                                )
                            }
                            {
                                active === 1 && user.id && (
                                    <Billing setSubscriptionDetails={(data) => setSubscriptionDetails(data)} updateHeaderCallback={updateHeaderCallback} onSave={onSave} user={user} />
                                )
                            }
                            {
                                active === 2 && user.id && (
                                    <Preferences onSave={onSave} user={user} />
                                )
                            }
                        </div>
                    }
                  
                </div>
               
            </div>
           
        </div>
    )
}