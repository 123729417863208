import { useState } from "react";
import { Col, Drawer, Row, Modal } from "rsuite";
// import SquareButton from "../SquareButton";
// import FileInput from "../FileInput";
// import Toast from "../../../services/toastService";
// import SelectInput from "../SelectInput";
import { InputNumber, Input, SelectPicker } from 'rsuite';
import { Uploader } from 'rsuite';
import { toast } from 'react-toastify';

const UploadFileModal = (props) => {

    const [fileList, setFileList] = useState([]);
    const [fileList1, setFileList1] = useState([]);
    const [errorSizeMessage, setErrorSizeMessage] = useState("");

    const onSave = () => {
        // const file
        if(fileList.length <1 && fileList1.length < 1){
            toast('Please select file to  upload!',{type:"error"});
            return;
        }
        props.onSave(fileList.length > 0 ? fileList : fileList1);
        setErrorSizeMessage("")
        onCancel()
    }

    const onCancel = () => {
        setFileList([])
        setFileList1([])
        props.onClose()
    }

    return (
        <Modal open={props.isVisible} onClose={onCancel}>
            <Modal.Header>
                <Modal.Title className="font-Poppins m-5 my-0 ml-0 font-bold text-xl">{}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow:'hidden'}}>
                <div className="font-Poppins">
                    <Uploader 
                        action="//jsonplaceholder.typicode.com/posts/" 
                        draggable 
                        autoUpload={false} 
                        multiple={false} 
                        fileList={fileList}
                        
                        // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(files) => {
                            // console.log("drag files ==", files);
                           setErrorSizeMessage('')
                            if(files.length > 0 && files[files.length-1].blobFile.size > 2000000){
                                setErrorSizeMessage("File size should not be more than 2MB")
                                setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                                setFileList([])
                            }else{
                            setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                            setFileList([])
                            }
                        }
                        }>
                            <div style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: "green", borderRadius:5 }}>
                                <span className="text-xl text-green-200 font-extrabold text-center">Drag &amp; Drop your .csv file <br/> anywhere in this box</span>
                            </div>
                    </Uploader>

                    <Uploader
                        className="w-full pl-3 mt-5 gap-x-1 font-medium cursor-pointer text-green-200 text-base"
                        autoUpload={false} 
                        fileList={fileList1}
                        accept=".csv"
                        multiple={false} 
                        action="//jsonplaceholder.typicode.com/posts/"
                        onChange={(files) => {
                            // console.log("drag files ==", files);
                            setErrorSizeMessage('')
                            if(files.length > 0 && files[files.length-1].blobFile.size > 2000000){
                                setErrorSizeMessage("File size should not be more than 2MB")
                                setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                                setFileList([])
                            }else{
                            setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                            setFileList([])
                            }
                           
                        }
                        }
                    >
                        <div>
                        <div className="underline underline-green-200 underline-offset-4">Or select file here</div>
                        {!errorSizeMessage && <div className="text-xs mt-5 text-gray-400">{"NOTE: File size should not exceed 2MB."}</div>}
                        <div className="text-red-500 mt-5">{errorSizeMessage}</div>
                        </div>
                        
                    </Uploader>
                    
                    <div className="flex justify-center gap-x-3 mt-10">
                        <div onClick={onCancel} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                            {`< Cancel`}
                        </div>
                        <div onClick={() => {
                            if(!errorSizeMessage)
                                onSave()
                            }} className={`${errorSizeMessage.length > 0 ? 'cursor-not-allowed' : 'cursor-pointer'}  flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg`}>
                           {`Import >`}
                        </div>
                    </div>
                    
                </div>
                    
            </Modal.Body>
            
        </Modal>
    );
};

UploadFileModal.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default UploadFileModal;
